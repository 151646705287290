export default class Header {
  constructor(container) {
    this.container = container;

    this.navMenu = this.container.querySelector(`.nav-menu`);
    this.navMenuToggle = this.navMenu.querySelector(`.nav-menu__button`);
    this.heightHeader = this.container.offsetHeight;
    this.widthContainer = this.container.offsetWidth;
    this.list = this.container.querySelectorAll(`.site-list__item--parent`);

    this.menuButtonClickHandler = this.menuButtonClickHandler.bind(this);
    this.navMenuEscKeyHandler = this.navMenuEscKeyHandler.bind(this);
    this.mouseoverMenuHandler = this.mouseoverMenuHandler.bind(this);
    this.mouseoutMenuHandler = this.mouseoutMenuHandler.bind(this);
  }

  init() {
    this.setMenuToggle();

    if (this.widthContainer > 768) {
      this.list.forEach((item, index) => {
        item.addEventListener(`mouseover`, this.mouseoverMenuHandler.bind(this, item));
        item.addEventListener(`mouseout`, this.mouseoutMenuHandler.bind(this, index));
      });
    }
  }

  setMenuToggle() {
    this.navMenuToggle.addEventListener(`click`, this.menuButtonClickHandler);
  }

  openNavMenu() {
    this.navMenu.classList.remove(`nav-menu--closed`);
    this.navMenu.classList.add(`nav-menu--opened`);
  }

  closeNavMenu() {
    this.navMenu.classList.remove(`nav-menu--opened`);
    this.navMenu.classList.add(`nav-menu--closed`);
  }

  menuButtonClickHandler(evt) {
    evt.preventDefault();

    if (this.navMenu.classList.contains(`nav-menu--closed`)) {
      this.openNavMenu();
      document.addEventListener(`keydown`, this.navMenuEscKeyHandler);
    } else {
      this.closeNavMenu();
    }
  }

  navMenuEscKeyHandler(evt) {
    if (evt.key === `Escape` || evt.key === `Esc`) {
      evt.preventDefault();
      this.closeNavMenu();
      document.removeEventListener(`keydown`, this.navMenuEscKeyHandler)
    }
  }

  mouseoverMenuHandler(item) {
    const targetSubMenu = item.querySelector(`ul`);

    if (this.heightSubMenu !== null) {
      const widthSubMenu = targetSubMenu.offsetWidth;
      const headerWrap = this.container.querySelector(`.header__wrap`);

      const is_style = headerWrap.currentStyle || window.getComputedStyle(headerWrap, null);
      const widthHeaderWrap = headerWrap.clientWidth - parseInt(is_style.paddingLeft)

      const widthCarrent = item.getBoundingClientRect().left + widthSubMenu;

      if (widthCarrent > widthHeaderWrap) {
        targetSubMenu.style.left = -(widthCarrent - widthHeaderWrap) + "px";
      }

      const heightSubMenu = targetSubMenu.offsetHeight;
      item.style.borderBottom = `3px solid #ffffff`;
      return this.container.style.height = this.heightHeader + heightSubMenu + "px";
    }
  }

  mouseoutMenuHandler(index) {
    this.list[index].style.borderBottom = `none`;
    this.container.style.height = this.heightHeader + "px";
  }
}
export default class Dropdown {
  constructor(container) {
    this.container = container;

    this.list = this.container.querySelectorAll('.faq__item');
    this.dropdownButtonClickHandler = this.dropdownButtonClickHandler.bind(this);
  }

  init() {
    this.list.forEach((item) => {
      item.addEventListener('click', this.dropdownButtonClickHandler);
    });
  }

  dropdownButtonClickHandler(evt) {
    const button = evt.target;

    if (button.classList.contains('faq__caption')) {
      const buttonBox = button.closest('li');

      if (buttonBox.classList.contains('faq__item--closed') === true) {
        this.closedBox()
        buttonBox.classList.remove('faq__item--closed');
      } else {
        buttonBox.classList.add('faq__item--closed');
      }
    }
  }

  closedBox() {
    this.list.forEach((item) => {
      if (item.classList.contains('faq__item--closed') !== true) {
        item.classList.add('faq__item--closed');
      }
    });
  }
}
import Header from "./header.js";
import Dropdown from "./dropdown.js";

const headerBox = document.querySelector('header');
const header = new Header(headerBox);
header.init();

const dropdownBox = document.querySelector('.faq');
const dropdown = new Dropdown(dropdownBox);
dropdown.init();

